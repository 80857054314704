const amplifyConfigure = {
    Auth: {
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: process.env.VUE_APP_AWS_MANDATORY_SIGN_IN,
        cookieStorage: {
            domain: process.env.VUE_APP_AMPLIFY_CONFIGURE_DOMAIN,
            expires: parseInt(process.env.VUE_APP_AMPLIFY_CONFIGURE_EXPIRES),
            path: process.env.VUE_APP_AMPLIFY_CONFIGURE_PATH,
            sameSite: process.env.VUE_APP_AMPLIFY_CONFIGURE_SAMESITE,
            secure: process.env.VUE_APP_AMPLIFY_CONFIGURE_SECURE
        }
    }
}
console.log(amplifyConfigure)
export default amplifyConfigure;