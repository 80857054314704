import App from "./App.vue";
import { Amplify } from 'aws-amplify';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import './styles/app.css';
import router from './router';
import amplifyConfigure from './utils/amplify';
import VueMask from '@devindex/vue-mask';

Amplify.configure(amplifyConfigure);
createApp(App)
.use(createPinia())
.use(router)
.use(VueMask)
.mount('#app')