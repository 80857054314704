<template>
  <div id='app' class="h-full bg-gray-50 ">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
    }
  },
}
</script>

<style>
</style>