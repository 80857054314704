import { createRouter, createWebHistory } from "vue-router";
import { Auth } from 'aws-amplify'

const Admin = () => import('./../layouts/Admin')
const Home = () => import('./../pages/Home')
const Profile = () => import('./../components/Profile')
const Permission = () => import('./../pages/Permission')
const authCognito = () => import('./../pages/Auth')
const cambioClave = () => import('./../pages/ChangePassword')
const Protected = () => import('./../components/Protected')
const Container = () => import('./../layouts/Container')
const PageNotFound = () => import('./../pages/404')
const ResetPassword = () => import('./../pages/PasswordReset')
const AptoCuentaMas = () => import('./../pages/AptoCuentaMas')
const routes = [
    {
        name: 'login',
        path: '/login',
        component: authCognito,
       
    },
    {
        name: 'change-password',
        path: '/cambio-clave',
        component: cambioClave,
    },
    {
        name: 'password-reset',
        path: '/reset-clave',
        component: ResetPassword,
    },
    {
        name: 'admin',
        path: '/',
        component: Admin,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'home',
                path: '/', 
                component: Home,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'filtroCuentaMas',
                path: '/filtro/cuenta-mas',
                component: AptoCuentaMas,
                meta: {
                    requiresAuth: true,
                    authorize: ['promotores']
                },
            },
            {
                name: 'profile',
                path: '/profile',
                component: Profile,
                meta: {
                    requiresAuth: true,
                    authorize: ['backoffice-administrador']
                }
            },
            {
                name: 'permission',
                path: '/permission',
                component: Permission,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    { 
        path: '/:pathMatch(.*)*',
        component: PageNotFound 
    }
]

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "bg-blue-800 text-white",
    routes
})

router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Auth.currentAuthenticatedUser().then(async () => {
            const { authorize } = await to.meta;
            const user = await Auth.currentAuthenticatedUser();
            const currentUser = user.signInUserSession.accessToken.payload["cognito:groups"];

            if (authorize) {
                if (!currentUser) {
                    //Logeeado pero sin current
                    return next({ path: '/permission', query: { returnUrl: to.path } });
                }
                // console.log(authorize.includes(currentUser));
                let find = currentUser.find((val, index) => {
                    return authorize.includes(val)
                  })
                  if(find) {
                    return next();
                  } else {
                    await next({
                       path: '/permission'
                       });
                  }
            } else {
                return next();
            }
        }).catch(async () => {
            await next({
                path: '/login'
            });
        });
    } else {
        next()
    }
})

export default router
// This level is solved.